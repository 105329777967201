import React from "react";
// import React, { useEffect } from "react"
import { about } from "../dummydata";
import man from "../components/images/man.png";
import ss from './images/awais.pdf.pdf'

export default function About() {
  const handleDownload = () => {
    // Replace 'path-to-your-file.pdf' with the actual path of your PDF file
    const link = document.createElement('a');
    link.href = ss; // File URL
    link.download = 'awais.pdf'; // File name for download
    link.click();
  };

  return (
    <>
      <section className="about">
        <div className="container ">
          {about.map((val, i) => (
            <>
              <div className="left">
                <img src={man} alt="" />
              </div>
              <div className="right col-lg-7 ms-5">
                <h1>About me</h1>
                <p>{val.desc}</p>
                <p>{val.desc1}</p>
             
           <button  onClick={handleDownload}  className="primaryBtn" data-aos="fade-up-right">
              Download CV
            </button>
      
  
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
}
