import {
  Settings,
  CropRotate,
  ViewInAr,
  PieChart,
  Code,
  BarChart,
  CloudOutlined,
  FavoriteBorder,
  Public,
  PersonOutlined,
  AddLocationAltOutlined,
  PhoneIphone,
  EmailOutlined,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  HelpOutline,
} from "@mui/icons-material";
import { FaFigma } from "react-icons/fa6";
import { BsWordpress } from "react-icons/bs";
import { IoLogoReact } from "react-icons/io5";
import img1 from './pages/images/AirBrush_20221201014007.jpg'
import img2 from './pages/images/ibiii.btw-20230409-0001.jpg'
import img3 from './pages/images/link.jpg'
import b1 from "./pages/images/marketing.webp";
import b2 from "./pages/images/agency.png";
import b3 from "./pages/images/designs.jpeg";

export const home = [
  {
    text: "HELLO I'M",
    name: "AWAIS ASIF",
    post: "WEB DESIGNER",
    design: "FRONT END DEVELOPER",
    desc: "As a full-stack developer, I bridge the gap between innovative design and robust functionality, crafting web experiences that are not just visually stunning but also intuitively user-friendly. Whether you're looking for a sleek web application or a comprehensive online platform, I'm here .",
  },
];
export const about = [
  {
    desc: "As a full-stack developer, I bridge the gap between innovative design and robust functionality, crafting web experiences that are not just visually stunning but also intuitively user-friendly. My passion lies in transforming complex challenges into seamless digital solutions, ensuring that every project I undertake not only meets but exceeds client expectations.",
    desc1:
      " Whether you're looking for a sleek web application or a comprehensive online platform, I'm here to turn your vision into a reality that stands out in the digital landscape.",
  },
];
export const services = [
  {
    id: 1,
    icon: <FaFigma />,
    title: "Figma Design",
    desc: "Crafting visually compelling designs that blend creativity with functionality, making your brand unforgettable",
  },
  {
    id: 2,
    icon: <Code />,
    title: "Front End Development",
    desc: "Delivering intuitive and engaging user interfaces with meticulous attention to detail in every aspect of front-end development.",

  },
  {
    id: 3,
    icon: <ViewInAr />,
    title: "Backend Development",
    desc: "Building robust backend systems with scalable architecture, ensuring smooth, secure, and efficient data handling for your applications.",
  },
  {
    id: 4,
    icon: <BsWordpress  />,
    title: " WordPress Websites	",
    desc: "Creating custom WordPress websites that are both visually striking and easy to manage, tailored to meet your unique business needs",
  },
  {
    id: 5,
    icon: <IoLogoReact  />,
    title: "React Development",
    desc: "Building responsive, high-performance web applications with a focus on clean, efficient React code.",
  },
  {
    id: 6,
    icon: <BarChart />,
    title: "Web Applications",
    desc: "Developing dynamic web applications that blend cutting-edge technology with user-centric design for seamless, interactive experiences.",
  },
];
export const project = [
  {
    id: 1,
    icon: <CloudOutlined />,
    num: "89",
    title: "HAPPY CLIENTS",
  },
  {
    id: 2,
    icon: <FavoriteBorder />,
    num: "231",
    title: "PROJECTS COMPLEATED",
  },
  {
    id: 3,
    icon: <Public />,
    num: "108",
    title: "FILES DOWNLOADED",
  },
  {
    id: 4,
    icon: <PersonOutlined />,
    num: "1446",
    title: "LIENS OF CODE",
  },
];
export const portfolio = [
  {
    id: 1,
    name: "Brand",
    category: "marketing",
    title: "Brex Logo",
  },
  {
    id: 2,
    name: "Brand",
    category: "design",
    title: "Brex Logo",
  },
  {
    id: 3,
    name: "Brand",
    category: "development",
    title: "Brex Logo",
  },
  {
    id: 4,
    name: "Brand",
    category: "marketing",
    title: "Brex Logo",
  },
  {
    id: 5,
    name: "Brand",
    category: "design",
    title: "Brex Logo",
  },
  {
    id: 6,
    name: "Brand",
    category: "development",
    title: "Brex Logo",
  },
];

export const testimonials = [
  {
    id: 1,
    text: "Creating tailored web solutions that perfectly align with your unique business goals and user needs.",
    image: img1,
    name: "AWAIS ASIF",
    post: "Mern Stack Developer",
  },
  {
    id: 2,
    text: "Building dynamic web applications that deliver seamless, interactive experiences across all devices.",
    image: img2,

    name: "AWAIS ASIF",
    post: "Web Application",
  },
  {
    id: 3,
    text: "Building responsive, high-performance web applications with a focus on clean, efficient React code.",
    image: img3,

    name: "AWAIS ASIF",
    post: "React Developer",
  },
];
export const blog = [
  {
    id: 1,
    title: "Digital Marketing",
    desc: "Driving online growth through targeted strategies that boost visibility, engagement, and conversions. I craft digital marketing campaigns .",
    cover: b1,
  },
  {
    id: 2,
    title: "Web Development",
    desc: "Creating responsive, high-performing websites that deliver seamless user experiences across all devices. I bring your vision to life with cutting-edge web development solutions.",
    cover: b2,
  },
  {
    id: 3,
    title: "Web Designs",
    desc: "Crafting visually stunning designs that capture attention and communicate your brand's message effectively. I turn ideas into compelling visual experiences.",
    cover: b3,
  },
];
export const contact = [
  {
    icon: <AddLocationAltOutlined />,
    text1: "Model town B Bahawalpur",
    text2: "Bahawalpur, Punjab, 63100",
  },
  {
    icon: <PhoneIphone />,
    text1: "+923188368138",
    text2: "+923098419744",
  },
  {
    icon: <EmailOutlined />,
    text1: "awaisasif096@gmail.com",
    text2: "codeinca.com",
  },
];
export const social = [
  {
    icon: <Facebook />,
  },
  {
    icon: <Twitter />,
  },
  {
    icon: <Instagram />,
  },
  {
    icon: <YouTube />,
  },
];
