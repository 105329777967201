import React from "react";
// import React, { useEffect } from "react"
import { home } from "../dummydata";
import Typewriter from "typewriter-effect";
import ss from './images/awais.pdf.pdf'
export default function Homee() {
  const handleDownload = () => {
    // Replace 'path-to-your-file.pdf' with the actual path of your PDF file
    const link = document.createElement('a');
    link.href = ss; // File URL
    link.download = 'awais.pdf'; // File name for download
    link.click();
  };

  return (
    <>
      <section className="hero">
        {home.map((val, i) => (
          <div className="hero-contanier">
            <h3>{val.text}</h3>
            <h1>
              <Typewriter
                options={{
                  strings: [`${val.name}`, `${val.post}`, `${val.design}`],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
            <p  data-aos="fade-left">{val.desc}</p>
          
           <button onClick={handleDownload}  className="primaryBtn" data-aos="fade-up-right">
              Download CV
            </button>
        
          </div>
        ))}
      </section>
    </>
  );
}
